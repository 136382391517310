.IFrame {
    border: 1px solid #efefef;
    border-radius: 5px;
    box-shadow: 2px 4px #fafafa;
    height: calc(75vh - 106px) !important;
    padding: 10px
}

@media screen and (max-width: 500px) {
    .IFrame {
        height: calc(75vh - 70px) !important;
    }
}